import { Layout, Menu } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";

const { Sider } = Layout;
declare let window: any;
interface SiderMenuProps {
  id: string;
  selectedKey?: any;
  onChange?: (value: any) => any;
  mode?: "inline" | "vertical" | "horizontal";
  theme?: "light" | "dark";
  options?: any[];
  primaryColor?: string;
  collapsedButtonColor?: string;
  className?: string;
  style?: any;
  collapsed?: boolean;
  value?: any;
  dataidfield?: string;
  datatitlefield?: string;
  dataparentidfield?: string;
  datalinkfield?: string;
  dataiconfield?: string;
  dataorderfield?: string;
  databackgroundcolorfield?: string;
  collapsible: boolean;
  menuMap: any;
  hamburgerControl?: boolean;
}

interface SiderMenuState {
  collapsed: boolean;
  hamburgerCollapsed: boolean;
  selectedKey: string;
  uniqueKey: Guid;
  openKeys: string[];
}

class SiderMenu extends PureComponent<SiderMenuProps, SiderMenuState> {
  private memoizedDynamicCssResult = "";

  private dataIdField?: string;

  private dataTitleField?: string;

  private dataParentIdField?: string;

  private dataLinkField?: string;

  private dataIconField?: string;

  private dataOrderField?: string;

  private dataBackgroundColorField?: string;

  constructor(props: SiderMenuProps) {
    super(props);
    this.state = {
      collapsed: props.value?.collapsed ?? false,
      hamburgerCollapsed: false,
      selectedKey: props.value?.selectedKey ?? "",
      uniqueKey: Guid.create(),
      openKeys: JSON.parse(localStorage.getItem(`openKeys_${props.id}`) || "[]")
    };
    this.initializeFieldKeys();
  }

  initializeFieldKeys = (): void => {
    if (!window.kuika?.isDesignTime) {
      this.dataIdField = this.props.dataidfield?.toLowerCase();
      this.dataTitleField = this.props.datatitlefield?.toLowerCase();
      this.dataParentIdField = this.props.dataparentidfield?.toLowerCase();
      this.dataLinkField = this.props.datalinkfield?.toLowerCase();
      this.dataIconField = this.props.dataiconfield?.toLowerCase();
      this.dataOrderField = this.props.dataorderfield?.toLowerCase();
      this.dataBackgroundColorField = this.props.databackgroundcolorfield?.toLowerCase();
    }
  };

  handleComponentStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const menuTitleColor = this.props.primaryColor;
    const parentNode = node.parentNode as Element;

    let grandParentNode: Element = null;
    let triggerCollapsePanel: any = null;
    if (parentNode) {
      (parentNode as any).style.display = "inline-block";
      grandParentNode = parentNode.parentNode as Element;
      if (
        window.kuika?.isDesignTime &&
        grandParentNode &&
        grandParentNode.children &&
        grandParentNode.children.length > 1
      ) {
        triggerCollapsePanel = grandParentNode.children[1];
      } else if (
        grandParentNode &&
        grandParentNode.children &&
        grandParentNode.children.length > 0 &&
        grandParentNode.children[0].children &&
        grandParentNode.children[0].children.length > 0 &&
        grandParentNode.children[0].children[0].children &&
        grandParentNode.children[0].children[0].children.length > 1
      ) {
        triggerCollapsePanel = grandParentNode.children[0].children[0].children[1];
      }
      if (triggerCollapsePanel && triggerCollapsePanel.style) {
        const svg = triggerCollapsePanel.children[0]?.children[0] as HTMLElement;
        if (svg && svg.style) {
          svg.style.fill = "#fff";
        }
      }
    }
  };

  componentDidMount() {
    this.setDynamicStyle();
    this.handleComponentStyling();

    const storedLastVisitedMenuId = localStorage.getItem(`lastVisitedMenuId_${this.props.id}`);
    const storedIsMenuCollapsed = localStorage.getItem(`isMenuCollapsed_${this.props.id}`);
    const storedIsHamburgerCollapsed = localStorage.getItem(`isHamburgerCollapsed_${this.props.id}`);

    if (!this.props.selectedKey && !this.state.selectedKey && storedLastVisitedMenuId) {
      this.handleSelectMenuItem({ key: storedLastVisitedMenuId });
    }

    if (this.props.value?.collapsed !== true) {
      if (storedIsMenuCollapsed === "true") {
        this.handleCollapseChange(true);
      } else {
        this.handleCollapseChange(false);
      }
    } else {
      this.handleCollapseChange(true);
    }

    if (storedIsHamburgerCollapsed === "true") {
      this.handleHamburgerCollapse(true);
    }

    const handleResize = () => {
      const isMobileOrTablet = window.innerWidth <= 768;
      if (!window.kuika?.isDesignTime && this.props.collapsible && isMobileOrTablet) {
        this.handleHamburgerCollapse(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }

  componentDidUpdate() {
    this.handleComponentStyling();
    this.setDynamicStyle();
  }

  static getDerivedStateFromProps(nextProps: SiderMenuProps) {
    if (window.kuika?.isDesignTime) {
      return {
        collapsed: nextProps.collapsed !== undefined ? nextProps.collapsed : false,
        selectedKey: nextProps.selectedKey ?? "",
        openKeys: JSON.parse(localStorage.getItem(`openKeys_${nextProps.id}`) || "[]")
      } as SiderMenuState;
    }
    const result = {} as SiderMenuState;
    result.collapsed = nextProps.value?.collapsed;
    result.selectedKey = nextProps.value?.selectedKey;
    return result;
  }

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} slider-menu_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const menuTitleColor = this.props.primaryColor;
    result += `.${className.trim()} .ant-menu-title-content {
      text-align: left;
    }`;
    result += `.${className.trim()} {
      border-inline-end: 0 !important;
    }`;
    result += `.${className.trim()} .ant-menu-submenu-horizontal > .ant-menu-submenu-title{
      min-height: 48px !important;
    }`;

    result += `.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a {
      color: black !important;
    }`;

    if (menuTitleColor) {
      result += `.${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content, .${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-item-icon, .${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: ${menuTitleColor} !important;
        transition: none !important;
      }`;
      result += `.${className.trim()} .ant-menu-item-selected {
        background: ${this.setOpacity(menuTitleColor, 0.2)};
      }`;

      result += `.${className.trim()} > .ant-menu-item-selected > .ant-menu-inline-collapsed-noicon {
        color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()} .ant-menu-item-selected {
        color: ${menuTitleColor} !important;
      }`;
      result += `.${className.trim()} .ant-menu-submenu-selected  .ant-menu-inline-collapsed-noicon {
        color: ${menuTitleColor} !important;
      }`;
      result += `.ant-menu-submenu-popup .ant-menu-item-selected {
        background: ${this.setOpacity(menuTitleColor, 0.2)};
      }`;
      result += `.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
        color: ${menuTitleColor} !important;
      }`;
      result += `.${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-item-icon, .${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content, .${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow  {
        color: ${menuTitleColor} !important;
      }`;
      result += `.${className.trim()} {
          width: auto !important;
        }`;
      if (this.props.style?.height || this.props.style?.maxHeight || this.props.style?.minHeight) {
        result += `.${className.trim()} {
            overflow-y: auto !important;
          }`;
      }
      result += `.${className.trim()}::before {
        content: none !important;
      }`;

      if (this.props.mode === "horizontal")
        result += `.${className.trim()}.ant-menu-horizontal .ant-menu-item-selected::after {
        border-bottom-color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()}.ant-menu-horizontal .ant-menu-item:hover::after {
        border-bottom-color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()}.ant-menu-horizontal .ant-menu-submenu-selected::after {
        border-bottom-color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()}.ant-menu-horizontal .ant-menu-submenu:hover::after {
        border-bottom-color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()}.ant-menu-horizontal .ant-menu-submenu-active::after {
        border-bottom-color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()}.ant-menu-horizontal .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: ${menuTitleColor} !important;
      }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey: string = this.state.uniqueKey.toString();
    const isDesignTime = window.kuika?.isDesignTime;
    if (!uniquekey) {
      return;
    }
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }

    const node = ReactDOM.findDOMNode(this) as any;
    const siders = node.querySelectorAll(".ant-layout-sider-children");
    if (siders && siders.length > 0 && isDesignTime) {
      const sider = siders[0] as HTMLElement;
      const siderHeight = sider.offsetHeight + 29;
      (sider.children[1] as any).style.top = `${siderHeight}px`;
      const siderTrigger = node.querySelector(".ant-layout-sider-trigger") as HTMLElement;
      if (siderTrigger) {
        siderTrigger.style.display = "none";
      }
      if (
        sider &&
        sider.style &&
        (this.props.style?.height || this.props.style?.maxHeight || this.props.style?.minHeight)
      ) {
        sider.style.overflowY = "auto";
      }
    }
    if (this.props.mode === "horizontal") {
      const siders = node.querySelectorAll(".ant-layout-sider");
      if (siders && siders.length > 0) {
        siders.forEach((s: any) => {
          s.style.width = "100%";
          s.style.maxWidth = "100%";
          s.style.minWidth = "100%";
        });
      }
      node.style.width = "100%";
      node.style.maxWidth = "100%";
      node.style.minWidth = "100%";
      const inlineSubmenus = node.querySelectorAll(".ant-menu-submenu-inline");
      if (inlineSubmenus && inlineSubmenus.length > 0) {
        inlineSubmenus.forEach((s: any) => {
          s.style.display = "inline-block";
          if (s.children && s.children.length > 0) {
          }
        });
      }
      if (node.parentNode && node.parentNode.style) {
        node.parentNode.style.width = "100%";
        node.parentNode.style.display = "block";
      }
      const sider = node.querySelector(".ant-layout-sider");
      if (sider && sider.style) {
        sider.style.display = "block";
        sider.style.width = "100%";
      }
      const siderChildren = node.querySelector(".ant-layout-sider-children");
      if (siderChildren && siderChildren.style) {
        siderChildren.style.width = "100%";
        siderChildren.style.display = "block";
      }

      const antMenu = node.querySelector(".ant-menu");
      if (antMenu) {
        antMenu.style.display = "flex";
        if (this.props.style?.height) {
          antMenu.style.height = this.props.style?.height;
        }
        if (this.props.style?.maxHeight) {
          antMenu.style.maxHeight = this.props.style?.maxHeight;
        }
        if (this.props.style?.minHeight) {
          antMenu.style.minHeight = this.props.style?.minHeight;
        }
      }
      const antMenuItems = node.querySelectorAll(".ant-menu-item");
      if (antMenuItems && antMenuItems.length > 0) {
        antMenuItems.forEach((item: any) => {
          item.style.minHeight = "48px";
        });
      }
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (!props.mode || props.mode === "vertical") {
      props.mode = "inline";
    }
    if (props.mode === "horizontal" && window.kuika?.isDesignTime) {
      props.overflowedIndicator = false;
      props.disabledOverflow = true;
      props.style.pointerEvents = "none";
    }
    return props;
  };

  setOpacity = (color: string, opacity: number | any) => {
    if (!color) return color;
    color = color.replace(/[\srgba()]/g, "");
    const rgbaValues = color.split(",");
    opacity = parseFloat(opacity);
    opacity = Math.min(Math.max(opacity, 0), 1);
    rgbaValues[3] = opacity.toString();
    const updatedColor = `rgba(${rgbaValues.join(",")})`;

    return updatedColor;
  };

  isColorCloserToWhiteOrBlack = (rgbaColor: string) => {
    const rgbaRegex: RegExp = /rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/;
    const match: RegExpMatchArray = rgbaColor.match(rgbaRegex);

    if (!match) {
      return true;
    }

    const [, r, g, b] = match.map(Number);

    const luminance: number = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 128;
  };

  handleSelectMenuItem = ({ key }) => {
    if (this.props.onChange) {
      this.props.onChange({
        selectedKey: key,
        collapsed: this.state.collapsed
      });
    }
    localStorage.setItem(`lastVisitedMenuId_${this.props.id}`, key);
  };

  handleOpenChange = (openKeys: string[]) => {
    this.setState({ openKeys });
    localStorage.setItem(`openKeys_${this.props.id}`, JSON.stringify(openKeys));
  };

  handleCollapseChange = (collapsed: boolean) => {
    if (this.props.onChange) {
      this.props.onChange({
        selectedKey: this.state.selectedKey,
        collapsed
      });
    }
    localStorage.setItem(`isMenuCollapsed_${this.props.id}`, collapsed.toString());
  };

  setSelectedKey = (key: string) => {
    this.handleSelectMenuItem({ key });
  };

  getSelectedKey = () => {
    let screenName;
    if (
      window.location.hash &&
      window.location.hash !== "" &&
      window.location.hash !== "/" &&
      window.location.hash !== "#" &&
      window.location.hash !== "#/"
    ) {
      screenName = window.location.hash.split("/").pop();
    } else if (window.location.hash === "#/") {
      if (this.props.menuMap && this.props.menuMap.length > 0) {
        const startingScreen = this.props.menuMap.find((item) => item.isStartingScreen);
        if (startingScreen) {
          return startingScreen.key;
        }
      }
      screenName = window.location.pathname?.split("/").pop();
    } else {
      screenName = window.location.pathname?.split("/").pop();
    }

    const items = this.getItems();
    if (items && items.length > 0) {
      const selectedScreen = items.find(
        (item) =>
          item.url?.toLowerCase() === screenName?.toLowerCase() ||
          item.url?.toLowerCase() === window.location.href?.toLowerCase()
      );
      if (selectedScreen) {
        return selectedScreen.key;
      }
    } else if (this.props.menuMap && this.props.menuMap.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const paramValues = Object.fromEntries(params.entries());

      const selectedKey = this.props.menuMap?.find((item) => {
        const screenNameMatches = item.screenName?.toLowerCase() === screenName?.toLowerCase();

        const paramsMatch =
          item.params &&
          Object.entries(item.params).every(
            ([key, value]) => paramValues[key]?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
          );

        return screenNameMatches && (!item.params || paramsMatch);
      });

      if (selectedKey) {
        return selectedKey.key;
      }
    }

    if (this.props.selectedKey) {
      return this.props.selectedKey;
    }
    if (this.state.selectedKey) {
      return this.state.selectedKey;
    }
    return localStorage.getItem(`lastVisitedMenuId_${this.props.id}`);
  };

  getParentOfSelectedKey = () => {
    let parentKey = "";
    const key = this.getSelectedKey();
    const items = this.getProps().options;
    if (items) {
      items.forEach((item) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child.key === key) {
              parentKey = item.key;
            }
          });
        }
      });
    }
    if (parentKey) {
      return parentKey;
    }
    return key;
  };

  getItems = () => {
    if (this.props.options && this.props.options?.length > 0) {
      return this.convertOptionsToItemData(this.props.options);
    }
    if ((!this.props.options || this.props.options?.length === 0) && this.props.dataidfield) {
      return [];
    }
  };

  lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  convertOptionsToItemData = (options: any[]): any[] => {
    const itemMap = {};

    options?.forEach((option) => {
      let optionLowercase = this.lowercaseKeys(option);
      const backgroundColor = optionLowercase[this.dataBackgroundColorField];

      itemMap[optionLowercase[this.dataIdField]] = {
        key: optionLowercase[this.dataIdField],
        label: optionLowercase[this.dataLinkField] ? (
          <a href={optionLowercase[this.dataLinkField]}>{optionLowercase[this.dataTitleField]}</a>
        ) : (
          optionLowercase[this.dataTitleField]
        ),
        icon: <span className="material-icons md-24">{optionLowercase[this.dataIconField]}</span>,
        order: optionLowercase[this.dataOrderField],
        url: optionLowercase[this.dataLinkField],
        style: backgroundColor ? { backgroundColor } : {}
      };
    });

    this.getParentChildMap(options, itemMap);
    const itemArray = Object.values(itemMap);

    itemArray.sort((a: any, b: any) => a.order - b.order);
    return itemArray ?? [];
  };

  getParentChildMap = (options: any[], itemMap) => {
    const itemsToDelete = [];

    options.forEach((item) => {
      let optionLowercase = this.lowercaseKeys(item);
      if (optionLowercase[this.dataParentIdField] && itemMap[optionLowercase[this.dataParentIdField]]) {
        const parentId = optionLowercase[this.dataParentIdField];
        const childId = optionLowercase[this.dataIdField];

        if (parentId !== childId) {
          if (!itemMap[parentId].children || itemMap[parentId].children.length === 0) {
            itemMap[parentId].children = [];
          }

          const childItem = itemMap[childId];
          if (childItem) {
            childItem.order = optionLowercase[this.dataOrderField];
            itemMap[parentId].children.push(childItem);

            itemsToDelete.push(childId);
          }
        }
      }
    });

    itemsToDelete.forEach((childId) => {
      delete itemMap[childId];
    });

    const sortChildren = (parent) => {
      if (parent.children) {
        parent.children.sort((a, b) => a.order - b.order);
        parent.children.forEach((child) => {
          sortChildren(child);
        });
      }
    };

    Object.values(itemMap).forEach((parent) => {
      sortChildren(parent);
    });

    return itemMap;
  };

  getSiderTriggerStyles = () => {
    const menuTitleColor = this.props.collapsedButtonColor;
    const triggerStyles: any = {};

    triggerStyles.backgroundColor = menuTitleColor;
    triggerStyles.height = "36px";
    triggerStyles.display = "flex";
    triggerStyles.alignItems = "center";
    triggerStyles.justifyContent = "center";
    triggerStyles.cursor = "pointer";
    return triggerStyles;
  };

  renderMenu = () => {
    return (
      <Menu
        className={this.getClassName()}
        {...this.getProps()}
        onSelect={this.handleSelectMenuItem}
        selectedKeys={[this.getSelectedKey()]}
        defaultSelectedKeys={[this.getSelectedKey()]}
        defaultOpenKeys={this.state.openKeys}
        openKeys={this.state.openKeys}
        onOpenChange={this.handleOpenChange}
        items={this.getItems()}
      />
    );
  };

  renderHamburgerIcon = () => {
    const { hamburgerCollapsed } = this.state;
    const { hamburgerControl } = this.props;

    if (!hamburgerControl) {
      return null;
    }

    const adjustLayout = (element: HTMLElement): string | null => {
      while (element) {
        if (element.id?.endsWith("_layout_left")) {
          return "left";
        }
        if (element.id?.endsWith("_layout_right")) {
          return "right";
        }
        element = element.parentElement as HTMLElement;
      }
      return null;
    };

    const parentNode = document.getElementById(this.props.id);
    const layoutPosition = parentNode ? adjustLayout(parentNode) : undefined;

    const headerElement = document.getElementById(
      Array.from(document.querySelectorAll("[id]")).find((el) => el.id.endsWith("_layout_header"))?.id
    );
    let headerHeight = 0;
    if (headerElement) {
      headerHeight += headerElement.offsetHeight;
    }

    let menuWidth = !hamburgerCollapsed ? (this.props.style?.width ? parseInt(this.props.style?.width) - 18 : 182) : 0;

    if (layoutPosition === "right" && hamburgerCollapsed) {
      menuWidth += 16;
    }

    if (this.state.collapsed && !hamburgerCollapsed) {
      menuWidth = 64;
    }

    const iconStyle: React.CSSProperties = {
      position: layoutPosition ? "absolute" : "unset",
      top: `0px`,
      [layoutPosition]: `${menuWidth}px`,
      cursor: "pointer",
      zIndex: 9999,
      transition: `${layoutPosition} 0.3s ease`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "36px",
      height: "36px"
    };

    return (
      <div style={iconStyle} onClick={() => this.handleHamburgerCollapse(!hamburgerCollapsed)}>
        {hamburgerCollapsed ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M12 22C7.286 22 4.929 22 3.464 20.535C2 19.072 2 16.714 2 12C2 7.286 2 4.929 3.464 3.464C4.93 2 7.286 2 12 2C16.714 2 19.071 2 20.535 3.464C22 4.93 22 7.286 22 12C22 16.714 22 19.071 20.535 20.535C19.072 22 16.714 22 12 22Z"
              fill={this.props.collapsedButtonColor}
              fillOpacity="0.5"
            />
            <path
              d="M5 7.83333C5 7.3731 5.3731 7 5.83333 7H18.1667C18.6269 7 19 7.3731 19 7.83333V7.83333C19 8.29357 18.6269 8.66667 18.1667 8.66667H5.83333C5.3731 8.66667 5 8.29357 5 7.83333V7.83333ZM5.83333 12.8333C5.3731 12.8333 5 12.4602 5 12V12C5 11.5398 5.3731 11.1667 5.83333 11.1667H18.1667C18.6269 11.1667 19 11.5398 19 12V12C19 12.4602 18.6269 12.8333 18.1667 12.8333H5.83333ZM5.83333 17C5.3731 17 5 16.6269 5 16.1667V16.1667C5 15.7064 5.3731 15.3333 5.83333 15.3333H18.1667C18.6269 15.3333 19 15.7064 19 16.1667V16.1667C19 16.6269 18.6269 17 18.1667 17H5.83333Z"
              fill={this.props.collapsedButtonColor}
            />
          </svg>
        ) : layoutPosition === "right" ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M12 22C7.286 22 4.929 22 3.464 20.535C2 19.072 2 16.714 2 12C2 7.286 2 4.929 3.464 3.464C4.93 2 7.286 2 12 2C16.714 2 19.071 2 20.535 3.464C22 4.93 22 7.286 22 12C22 16.714 22 19.071 20.535 20.535C19.072 22 16.714 22 12 22Z"
              fill={this.props.collapsedButtonColor}
              fill-opacity="0.5"
            />
            <path
              d="M5.50515 14.4595C5.22072 14.7685 5.22408 15.245 5.51283 15.55C5.82937 15.8843 6.36223 15.8827 6.67671 15.5464L9.49493 12.5329C9.77542 12.233 9.77542 11.767 9.49493 11.4671L6.67671 8.45357C6.36223 8.11729 5.82937 8.11566 5.51283 8.45C5.22408 8.75499 5.22072 9.23147 5.50515 9.54051L7.76889 12L5.50515 14.4595ZM19 7.83333C19 7.3731 18.6269 7 18.1667 7H9.72222C9.26198 7 8.88889 7.3731 8.88889 7.83333C8.88889 8.29357 9.26198 8.66667 9.72222 8.66667H18.1667C18.6269 8.66667 19 8.29357 19 7.83333ZM18.1667 12.8333C18.6269 12.8333 19 12.4602 19 12C19 11.5398 18.6269 11.1667 18.1667 11.1667H12.0556C11.5953 11.1667 11.2222 11.5398 11.2222 12C11.2222 12.4602 11.5953 12.8333 12.0556 12.8333H18.1667ZM18.1667 17C18.6269 17 19 16.6269 19 16.1667C19 15.7064 18.6269 15.3333 18.1667 15.3333H9.72222C9.26198 15.3333 8.88889 15.7064 8.88889 16.1667C8.88889 16.6269 9.26198 17 9.72222 17H18.1667Z"
              fill={this.props.collapsedButtonColor}
            />
          </svg>
        ) : (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M12 22C7.286 22 4.929 22 3.464 20.535C2 19.072 2 16.714 2 12C2 7.286 2 4.929 3.464 3.464C4.93 2 7.286 2 12 2C16.714 2 19.071 2 20.535 3.464C22 4.93 22 7.286 22 12C22 16.714 22 19.071 20.535 20.535C19.072 22 16.714 22 12 22Z"
              fill={this.props.collapsedButtonColor}
              fillOpacity="0.5"
            />
            <path
              d="M18.4948 14.4595C18.7793 14.7685 18.7759 15.245 18.4872 15.55V15.55C18.1706 15.8843 17.6378 15.8827 17.3233 15.5464L14.5051 12.5329C14.2246 12.233 14.2246 11.767 14.5051 11.4671L17.3233 8.45357C17.6378 8.11729 18.1706 8.11566 18.4872 8.45V8.45C18.7759 8.75499 18.7793 9.23147 18.4948 9.54051L16.2311 12L18.4948 14.4595ZM5 7.83333C5 7.3731 5.3731 7 5.83333 7H14.2778C14.738 7 15.1111 7.3731 15.1111 7.83333V7.83333C15.1111 8.29357 14.738 8.66667 14.2778 8.66667H5.83333C5.3731 8.66667 5 8.29357 5 7.83333V7.83333ZM5.83333 12.8333C5.3731 12.8333 5 12.4602 5 12V12C5 11.5398 5.3731 11.1667 5.83333 11.1667H11.9444C12.4047 11.1667 12.7778 11.5398 12.7778 12V12C12.7778 12.4602 12.4047 12.8333 11.9444 12.8333H5.83333ZM5.83333 17C5.3731 17 5 16.6269 5 16.1667V16.1667C5 15.7064 5.3731 15.3333 5.83333 15.3333H14.2778C14.738 15.3333 15.1111 15.7064 15.1111 16.1667V16.1667C15.1111 16.6269 14.738 17 14.2778 17H5.83333Z"
              fill={this.props.collapsedButtonColor}
            />
          </svg>
        )}
      </div>
    );
  };

  handleHamburgerCollapse = (hamburgerCollapsed: boolean) => {
    this.setState({ hamburgerCollapsed });
    localStorage.setItem(`isHamburgerCollapsed_${this.props.id}`, hamburgerCollapsed.toString());
  };

  render(): ReactNode {
    const { hamburgerControl } = this.props;
    const { collapsed, hamburgerCollapsed } = this.state;
    const isDesignTime = window.kuika?.isDesignTime;

    return (
      <>
        {hamburgerControl && !isDesignTime && this.renderHamburgerIcon()}
        {this.props.mode !== "horizontal" ? (
          <Sider
            collapsed={collapsed}
            onCollapse={(e) => this.handleCollapseChange(e)}
            trigger={null}
            width={this.props.style?.width ?? 200}
            style={{ display: hamburgerControl && hamburgerCollapsed ? "none" : "block" }}
          >
            {this.renderMenu()}
            {this.props.collapsible && (
              <div
                className="ant-layout-sider-trigger"
                style={{ ...this.getSiderTriggerStyles() }}
                onClick={() => this.handleCollapseChange(!collapsed)}
              >
                {this.props.value?.collapsed || this.props.collapsed ? (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="#fff"
                    aria-hidden="true"
                  >
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                  </svg>
                ) : (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="#fff"
                    aria-hidden="true"
                  >
                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                  </svg>
                )}
              </div>
            )}
          </Sider>
        ) : (
          this.renderMenu()
        )}
      </>
    );
  }
}

const siderMenu = withCommonEvents(SiderMenu);
export { siderMenu as SiderMenu };
