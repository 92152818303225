import { Select, Spin } from "antd/es";
import { SelectProps } from "antd/es/select";
import _, { debounce } from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKSelectBoxProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  widthType?: "fill" | "content";
  onSearchValueChange: (searchValue: string) => void;
  getTextFromValue: (value: any) => void;
}

interface IKSelectBoxState {
  fetching: boolean;
}

class DebounceSelect extends PureComponent<IKSelectBoxProps & CommonProps, IKSelectBoxState> {
  constructor(props: IKSelectBoxProps) {
    super(props);
    this.state = {
      fetching: false
    };
  }

  waitAndSearch = debounce((value) => this.handleSearch(value), 800);

  handleSearch = (value) => {
    if (this.props.onSearchValueChange) {
      this.props.onSearchValueChange(value);
    }
  };

  handleChange = (value) => {
    this.setState({
      fetching: false
    });

    if (this.props.onChange) {
      this.props.onChange(value, []);
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    return props;
  };

  render(): ReactNode {
    const { fetching } = this.state;
    return (
      <Select
        {...this.getProps()}
        labelInValue
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.waitAndSearch}
        onChange={this.handleChange}
        showSearch
        loading={fetching}
      ></Select>
    );
  }
}

const kSelectBox = withCommonEvents(DebounceSelect);
export { kSelectBox as KDebounceSelect };
