import { SelectProps } from "antd/es";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../common/common-props";
import { SingleSelect } from "../single-select/single-select";

interface IMultiSelectProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  onChange?: (value: any) => void;
  defaultText?: string;
  sortBy?: string;
  value?: string | any[];
}

interface IMultiSelectState {}

class MultiSelect extends PureComponent<IMultiSelectProps & CommonProps, IMultiSelectState> {
  constructor(props: IMultiSelectProps) {
    super(props);
  }

  private normalizeValue = (value: string | any[] | undefined): any[] | undefined => {
    if (!value) return undefined;

    if (typeof value === "string") {
      return value
        .split(",")
        .map((v) => v.trim())
        .filter(Boolean);
    }

    return Array.isArray(value) ? value : [value];
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);

    if (props.options && Array.isArray(props.options)) {
      props.options = props.options.map((option: any) => ({
        ...option,
        compareKey: option?.key?.toString()?.toLowerCase()
      }));
    } else {
      props.options = [];
    }

    if (props.value) {
      const values = this.normalizeValue(props.value);
      props.value = values
        ?.map((v) => {
          if (v == null) return v;
          const matchingOption = props.options?.find((opt: any) => opt?.compareKey === v?.toString()?.toLowerCase());
          return matchingOption?.key ?? v;
        })
        .filter(Boolean);
    }

    props.mode = "multiple";
    style.display = "inline-block";
    props.style = style;

    if (!props.value) {
      props.value = undefined;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <SingleSelect {...this.getProps()}></SingleSelect>
      </>
    );
  }
}

const multiSelect = MultiSelect;
export { multiSelect as MultiSelect };
