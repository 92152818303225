import { ColumnType } from "antd/es/table";
import _ from "lodash";
import React, { PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";

export interface TableDataProps extends ColumnType<any> {
  rowData?: any;
  children?: React.ReactNode;
  isEditableColumn?: boolean;
  isEditMode?: boolean;
  visibilityCondition?: (rowData: object) => boolean;
  editabilityCondition?: (rowData: object) => boolean;
}

declare let window: any;
interface KTableDataState {}

class TableData<P, S> extends PureComponent<TableDataProps & P & CommonProps, KTableDataState & S> {
  constructor(props: TableDataProps & P & CommonProps) {
    super(props);
  }

  static dataFieldKey: RegExp = /\[datafield:((?:(?!\[datafield:).)*)\]/;

  static oldValue: RegExp = /\[datafield:((?:(?!\[datafield:).)*)\]/g;

  static toCamelCase = (str: string): string => {
    return str.replace(/^([A-Z])|[\s-](\w)/g, (match, p1, p2) => {
      if (p2) {
        return p2.toUpperCase();
      }

      return p1.toLowerCase();
    });
  };

  static getValueFromPath = (data: object, path: string, index: number) => {
    // Example path: "Users[first].Name
    const fields: string[] = path
      .split(".")
      .filter((field: string, index: number, array: string[]) => {
        const theFieldIndexContaingSelected = array.findIndex((x) => x.includes("[selected]"));

        if (theFieldIndexContaingSelected > -1) {
          return index > theFieldIndexContaingSelected;
        }

        return true;
      })
      .map((x) => TableData.toCamelCase(x));

    const value = fields.reduce((prev: any, curr: string) => {
      if (!prev) return "";
      let result = null;

      if (curr.endsWith("[first]")) {
        const field: string = curr.replace("[first]", "");
        result = prev?.[field]?.at?.(0);
      } else if (curr.endsWith("[last]")) {
        const field: string = curr.replace("[last]", "");
        result = prev?.[field]?.at?.(-1);
      } else if (curr.endsWith("[selected]")) {
        const field: string = curr.replace("[selected]", "");
        result = prev?.[field]?.at?.(index);
      } else {
        const field: string = curr;
        result = prev?.[field];
      }

      return result;
    }, data);

    return value;
  };

  static getIsDataRepeater = (child: any): boolean => {
    if (!child) return false;

    if (child?.props?.dataSource && child.props.dataSource?.length > 0) {
      return true;
    }

    return false;
  };

  static setDataFieldValues = (
    children: any,
    rowData?: any,
    rowIndex?: number,
    _visibilityCondition?: (rowData: object) => boolean,
    _editabilityCondition?: (rowData: object) => boolean
  ): React.ReactNode[] => {
    if (!children) return [];

    return React.Children.map(children, (child, childIndex) => {
      const props: Record<string, any> = {};
      let manipulatedChildren: React.ReactNode[] = [];

      if (child?.props) {
        const visibilityCondition = child.props?.visibilityCondition || _visibilityCondition;

        if (visibilityCondition) {
          if (child?.props?.visibilityByAuthorization === "hidden") {
            props.visibility = "hidden";
          } else {
            props.visibility = visibilityCondition(rowData) === true ? "visible" : "hidden";
          }
        }

        const editabilityCondition = child.props?.editabilityCondition || _editabilityCondition;

        if (editabilityCondition) {
          if (child?.props?.editabilityByAuthorization === "disabled") {
            props.editability = "disabled";
          } else {
            props.editability = editabilityCondition(rowData) === true ? "enabled" : "disabled";
          }
        }

        Object?.keys(child.props)?.forEach((key) => {
          if (typeof child.props[key] === "string") {
            if (child.props[key]?.toString().indexOf("datafield:") > -1) {
              const matchResult = child.props[key]?.toString().match(TableData.dataFieldKey);

              if (rowData && matchResult && matchResult?.length > 0) {
                props[`${key}Original`] = child.props[key];

                const dataField = matchResult[1];
                let newValue = this.getValueFromPath(rowData, dataField, rowIndex) ?? "";

                if (Array.isArray(newValue) || _.isObject(newValue)) {
                  props[key] = newValue;
                } else {
                  props[key] = child.props[key]?.toString().replace(TableData.oldValue, newValue);
                }

                if (
                  !window ||
                  !window.kuika ||
                  (window.kuika?.dashboardState !== 1 && window.kuika?.dashboardState !== 17)
                ) {
                  if ((key === "dataSource" && props[key] == "") || props[key] == "null" || props[key] == "undefined") {
                    props[key] = [];
                  } else if (props[key] == "" || props[key] == "null" || props[key] == "undefined") {
                    props[key] = " ";
                  }
                }
              }
            }

            if (key === "children") {
              manipulatedChildren.push(props.children);
            }
          } else if (key === "children") {
            if (TableData.getIsDataRepeater(child)) {
              return;
            }

            manipulatedChildren = TableData.setDataFieldValues(child.props.children, rowData, rowIndex);
          }

          if (!props[key]) {
            props[key] = child.props[key];
          }
        });
      }

      props.rowIndex = rowIndex;

      if (manipulatedChildren?.length > 0) {
        return React.cloneElement(child, props, manipulatedChildren);
      }

      return React.cloneElement(child, props, child.props.children);
    });
  };

  render = () => {
    if (this.props.visibility === "hidden") return <></>;
    return TableData.setDataFieldValues(
      this.props.children,
      this.props.rowData,
      this.props.rowIndex,
      this.props.visibilityCondition,
      this.props.editabilityCondition
    );
  };
}

const tableData = withCommonEvents<TableDataProps & CommonProps, KTableDataState>(TableData);
export { tableData as TableData };
