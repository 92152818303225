import { Input } from "antd/es";
import { InputProps } from "antd/es/input";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import InputMask from "react-input-mask";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { Icon } from "../icon/icon";

declare let window: any;
const defaultAllowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Enter", "Delete"];

export interface TextboxProps extends Omit<InputProps, "autoComplete"> {
  prefix?: string;
  suffix?: string;
  iconColor?: string;
  mask?: string;
  formatter: "None" | "Lowercase" | "Uppercase";
  isCharOnly?: boolean;
  allowedChars?: string;
  maxLength?: number;
  autoComplete?: boolean;
  isNumberOnly?: boolean;
  inputMode?: any | "char" | "all" | "number";
}

interface TextboxState {
  mask: string;
}

class TextInput extends PureComponent<TextboxProps & CommonProps, TextboxState> {
  constructor(props: TextboxProps) {
    super(props);
    this.state = {
      mask: props.mask
    };
  }

  setMask(value: string) {
    this.setState({ mask: value });
  }

  handleAffixStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const input = node.getElementsByTagName("input")[0];
    if (!input) {
      return;
    }
    if (this.props.style?.backgroundColor) {
      input.style.backgroundColor = this.props.style?.backgroundColor as any;
    }
    if (this.props.style?.color) {
      input.style.color = this.props.style?.color as any;
    }
  };

  getPrefixSuffixStyles = (): React.CSSProperties => {
    const { style, iconColor } = this.props;

    const result: React.CSSProperties = {
      color: iconColor
    };

    if (style.fontSize) {
      result.fontSize = `calc(${style.fontSize} * 1.5714285714285714)`;
    }

    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);
    const prefixSuffixStyles = this.getPrefixSuffixStyles();
    const isDesignTime = window?.kuika?.isDesignTime === true;

    if (style.display === "block") {
      style.display = "flex";
    } else if (style.display === "inline") {
      style.display = "inline-flex";
    }
    if (props.prefix && props.prefix.length > 0) {
      props.prefix = <Icon style={prefixSuffixStyles} iconName={this.props.prefix as string}></Icon>;
    } else if (props.prefix) {
      delete props.prefix;
    }

    if (props.suffix && props.suffix.length > 0) {
      props.suffix = <Icon style={prefixSuffixStyles} iconName={this.props.suffix as string}></Icon>;
    } else if (props.suffix) {
      delete props.suffix;
    }
    if (props.iconColor) {
      delete props.iconColor;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props?.value?.toString) {
      if (this.props.formatter === "Uppercase") {
        props.value = props.value.toString().toLocaleUpperCase("TR");
      } else if (this.props.formatter === "Lowercase") {
        props.value = props.value.toString().toLocaleLowerCase("TR");
      } else {
        props.value = props.value.toString();
      }
      if (this.props.isCharOnly || this.props?.inputMode === "char") {
        props.value = props.value.toString().replace(/[^a-zA-ZğüşöçİĞÜŞÖÇ\s]/gi, "");
      } else if (this.props?.inputMode === "number") {
        props.value = props.value.toString().replace(/[^0-9]/g, "");
      } else {
        props.value = props.value.toString();
      }
      if (props.maxLength && !isDesignTime && !props.mask) {
        props.value = props.value.toString().substring(0, props.maxLength);
      }
      if (props.mask) {
        delete props.maxLength;
      }
    }

    if (props?.autoComplete === false) {
      props.autoComplete = "off";
    }

    if (!props?.formatCharacters) {
      props.formatCharacters = {
        "0": {
          validate() {
            return "0";
          }
        },
        "2": {
          validate() {
            return "2";
          }
        },
        "3": {
          validate() {
            return "3";
          }
        },
        "4": {
          validate() {
            return "4";
          }
        },
        "5": {
          validate() {
            return "5";
          }
        },
        "6": {
          validate() {
            return "6";
          }
        },
        "7": {
          validate() {
            return "7";
          }
        },
        "8": {
          validate() {
            return "8";
          }
        },
        "9": {
          validate() {
            return "9";
          }
        }
      };
    }

    props.style = style;

    return props;
  };

  componentDidMount() {
    if (
      this.props.style &&
      (this.props.style?.backgroundColor || this.props.style?.color) &&
      (this.props.prefix || this.props.suffix || this.props.allowClear)
    ) {
      this.handleAffixStyling();
    }

    this.setState({ mask: this.props.mask });
  }

  componentDidUpdate(prevProps: Readonly<TextboxProps & CommonProps>): void {
    if (prevProps.mask !== this.props.mask) {
      this.setState({ mask: this.props.mask });
    }
  }

  handleOnChange = (e: any) => {
    if (e.type === "click") {
      e.target.value = "";
      this.props.onPressEnter?.(e);
    }

    if (this.props?.isCharOnly === true || this.props?.inputMode === "char") {
      e.target.value.replace(/[^a-zA-ZığüşöçİĞÜŞÖÇ\s]/gi, "");
    }

    if (this.props?.inputMode === "number") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    if (this.props.allowedChars && this.props.allowedChars.length > 0) {
      const charArr = this.props.allowedChars.split("");
      e.target.value = e.target.value
        ?.split("")
        ?.filter((char) => charArr.includes(char))
        ?.join("");
    }

    if (this.props.onChange) {
      if (this.props.formatter === "Uppercase") {
        e.target.value = (e.target.value as string).toLocaleUpperCase("TR");
      } else if (this.props.formatter === "Lowercase") {
        e.target.value = (e.target.value as string).toLocaleLowerCase("TR");
      }
      this.props.onChange(e);
    }
  };

  render(): ReactNode {
    if (this.state.mask && window?.kuika?.isDesignTime !== true) {
      return (
        <InputMask {...this.getProps()} mask={this.state.mask} onChange={this.handleOnChange}>
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      );
    }
    return (
      <>
        <Input {...this.getProps()} onChange={this.handleOnChange}></Input>
      </>
    );
  }
}

const textInput = withCommonEvents(TextInput);
export { textInput as TextInput };
